import request from '@/../utils/request'
const api_name = '/iotechserver/AppTaskFormController'

export default {
    // 查询
    allHistoryList(taskForm) {
        return request({
            url: `${api_name}/allHistoryList`,
            method: 'post',
            data: taskForm
        })
    },


     // 查询详情
     queryList(taskForm) {
        return request({
            url: `${api_name}/queryList`,
            method: 'post',
            data: taskForm
        })
    },

}