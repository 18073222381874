<template>
  <div id="app" class="url" style="padding: 15px 0">
    <div>
      <el-row>
        <el-col :span="24" style="margin-bottom: 10px">
          <el-page-header @back="goBack" content="任务信息"> </el-page-header>
        </el-col>
      </el-row>
    </div>
    <div>
      <el-table
        border
        :data="
          tableData.filter(
            (data) =>
              !search ||
              data.jobNumber.toLowerCase().includes(search.toLowerCase()) ||
              data.deviceId.toLowerCase().includes(search.toLowerCase())
          )
        "
        style="width: 100%; padding-bottom: 40px"
         :row-style="rowStyle" :highlight-current-row="row"
      >
        <el-table-column prop="jobNumber" label="工单号" width="auto">
        </el-table-column>
        <el-table-column prop="deviceId" label="执法仪号" width="150px">
        </el-table-column>
        <el-table-column prop="workPlace" label="工作内容" width="150px">
        </el-table-column>
        <el-table-column prop="workPlace" label="工作地点" width="auto">
        </el-table-column>
        <el-table-column label="任务状态" width="180">
          <template slot-scope="scope">
            <el-tag size="medium" v-if="scope.row.isComplete == 0"
              >已提交</el-tag
            >
            <el-tag
              size="medium"
              type="success"
              v-if="
                scope.row.isComplete == 1 &&
                scope.row.powerResult.substring(0, 1) == 1
              "
              >已完成</el-tag
            >
            <el-tag size="medium" type="info" v-if="scope.row.isComplete == 2"
              >进行中</el-tag
            >
            <el-popover
              trigger="hover"
              placement="top"
              v-if="
                scope.row.powerResult != null &&
                scope.row.powerResult.substring(0, 1) == 2
              "
            >
              <p>理由: {{ scope.row.remarks }}</p>
              <div slot="reference" class="name-wrapper">
                <el-tag size="medium" type="warning">已驳回</el-tag>
              </div>
            </el-popover>
          </template>
        </el-table-column>

        <el-table-column align="center" width="auto">
          <template slot="header" slot-scope="scope">
            <el-input
              v-model="search"
              size="medium"
              placeholder="输入工单号或提交人搜索"
            />
          </template>

          <template slot-scope="scope">
            <el-button
              size="medium"
              type="primary"
              @click="handleClick(scope.row)"
              >查看</el-button
            >

            <el-button
              size="medium"
              type="success"
              @click="selectPeople(scope.row)"
              >视频列表</el-button
            >
            <el-button
              size="medium"
              type="success"
              @click="record(scope.row)"
              >执法记录仪</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div>
      <el-row style="">
        <el-pagination
          :current-page.sync="appPage.current"
          :page-size="appPage.limit"
          :total="appPage.total"
          layout="total, prev, pager, next, jumper"
          @current-change="allHistoryList"
        ></el-pagination>
      </el-row>
    </div>
    <div>
      <el-dialog
        :title="'任务单号：' + taskFormOne.jobNumber"
        :visible.sync="taskFormdialog"
        width="50%"
        @close="cancelTaskFormdialog"
      >
        <div style="height: auto">
          <div v-if="pickMess">
            <h1 style="border-bottom: 1px solid">
              <b><center>审批人员信息</center></b>
            </h1>
            <br />
            <el-form
              disabled
              :inline="true"
              :model="spUser"
              class="demo-form-inline"
            >
              <el-col :span="12">
                <el-form-item label="姓 名">
                  <el-input v-model="spUser.name"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="身份证号">
                  <el-input v-model="spUser.card"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="电 话">
                  <el-input
                    v-model="spUser.phone"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="用户账号">
                  <el-input v-model="spUser.userName"></el-input>
                </el-form-item>
              </el-col>
            </el-form>
          </div>
          <div>
            <h1 style="border-bottom: 1px solid">
              <b><center>提交人员信息</center></b>
            </h1>
            <br />
            <el-form
              disabled
              :inline="true"
              :model="tjUSer"
              class="demo-form-inline"
            >
              <el-col :span="12">
                <el-form-item label="姓 名">
                  <el-input v-model="tjUSer.name"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="身份证号">
                  <el-input v-model="tjUSer.card"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="电 话">
                  <el-input v-model="tjUSer.phone"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="用户账号">
                  <el-input v-model="tjUSer.userName"></el-input>
                </el-form-item>
              </el-col>
            </el-form>
          </div>
          <div>
            <h1 style="border-bottom: 1px solid">
              <b><center>作业人员信息</center></b>
            </h1>
            <br />
            <el-table
              :data="taskFormMess"
              border
              style="width: 100%; padding-bottom: auto; padding-top: 5px"
            >
              <el-table-column prop="name" label="姓名" width="100">
              </el-table-column>
              <el-table-column prop="card" label="身份证" width="auto">
              </el-table-column>
              <el-table-column prop="phone" label="电话号" width="auto">
              </el-table-column>
              <el-table-column prop="deviceId" label="设备号" width="auto">
              </el-table-column>
            </el-table>
          </div>

          <div>
            <el-form
              disabled
              :inline="true"
              :model="taskFormOne"
              class="demo-form-inline"
            >
              <h1 style="border-bottom: 1px solid">
                <b><center>作业信息</center></b>
              </h1>
              <br />
              <el-col :span="12">
                <el-form-item label="气体浓度">
                  <el-input v-model="taskFormOne.ocontent"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="工作地点">
                  <el-input v-model="taskFormOne.workPlace"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="报备时间">
                  <el-input v-model="taskFormOne.generationTime"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="开始时间">
                  <el-input v-model="taskFormOne.startTime"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="结束时间">
                  <el-input v-model="taskFormOne.enddingTime"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12"> 
                <!-- {{tjUSers}} -->
              </el-col>
            </el-form>
          </div>

          <div>
            <el-table empty-text=" " style="width: 100%"> </el-table>
          </div>
        </div>
      </el-dialog>
    </div>

    <div>
      <el-dialog
        title="成员信息"
        :visible.sync="taskFormPeopledialog"
        width="50%"
        @close="cancelTaskPeopleFormdialog"
      >
        <div style="height: auto">
          <div>
            <el-table
              :data="taskFormPeople"
              border
              style="width: 100%; padding-bottom: auto; padding-top: 5px"
            >
              <el-table-column prop="name" label="姓名" width="100">
              </el-table-column>
              <el-table-column prop="deviceId" label="设备号" width="auto">
              </el-table-column>
              <el-table-column label="操作" width="auto">
                <template slot-scope="scope">
                  <div v-if=" scope.row.openId == null">
                    <!-- 已完成 -->
                    <el-button
                      type="primary"
                      v-if="
                        scope.row.isComplete == 1 &&
                        scope.row.powerResult.substring(0, 1) == 1
                      "
                      >查看回放</el-button
                    >
                    <!-- 进行中 -->
                    <el-button type="primary" v-if="scope.row.isComplete == 2"
                    @click="watchVideo(scope.row)"
                      >查看视频</el-button
                    >
                    <!-- 驳回 -->
                    <el-button
                      v-if="
                        scope.row.powerResult != null &&
                        scope.row.powerResult.substring(0, 1) == 2
                      "
                      disabled
                      type="warning"
                      >驳回无视频</el-button
                    >
                    <el-button
                      v-if="scope.row.isComplete == 0"
                      type="warning"
                      disabled
                      >暂未开始</el-button
                    > 
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import taskFormApi from "@/api/manageApi/AppTaskForm";

export default {
  data() {
    return {
      row:true,
      pickMess: false,
      taskFormMess: [], // 人员信息
      taskFormOne: {}, // 任务基本信息
      taskFormUser: [], // 人员信息
      spUser: {}, // 审批人信息
      tjUSer: {}, // 提交人信息
      taskFormdialog: false,
      taskFormPeopledialog: false,

      appPage: {
        current: 1,
        limit: 10,
        total: 0,
      },
      taskFormPeople: [],

      tableData: [{}],
      search: "",
    };
  },
  created() {
    this.allHistoryList();
  },
  computed:{
    // tjUSers() {
    //   if (this.taskFormUser.length === 0) {
    //     console.log(222222222222);
    //     console.log(this.taskFormUser);
    //     return
    //   }else{
    //     console.log(this.taskFormUser);
    //     console.log(11111111);
    //     const aaa = this.taskFormUser.filter(item => item.power == 0)[0]
    //     return aaa;
    //   }
    // }
  },
  methods: {
    record(row){
      this.$router.push({
        path:'./VideoRecord',
        query: {
          deviceId:row.deviceId,
          randomId: Math.floor((Math.random() * 10000) + 1)
        }
      }).catch(err => err)
    },
    watchVideo(row){
      this.$router.push({
        path:'./Video',
        query: {
          deviceId:row.deviceId,
          randomId: Math.floor((Math.random() * 10000) + 1)
        }
      }).catch(err => err)
    },
    selectPeople(row) {
      //  this.$router.push("../../components/video/play.vue");
      const isComplete = row.isComplete
      const powerResult = row.powerResult

      this.taskFormPeopledialog = true;
      let taskForm = row;
      taskFormApi.queryList(taskForm).then((res) => {
        // 报备基本信息
        this.taskFormPeople = res.data.data.list;
        this.taskFormPeople.map( (item,index) =>{
            item.isComplete = isComplete,
            item.powerResult = powerResult
            return item
          },
        )
      });
    },
    handleClick(row) {
      this.taskFormdialog = true;
      let taskForm = row;
      taskFormApi.queryList(taskForm).then((res) => {
        console.log(res);
        // 报备人员信息
        this.taskFormMess = res.data.data.list;
        // 任务信息
        this.taskFormOne = res.data.data.one; 

        const arr = res.data.data.one1

        arr.map(item => item.phone = item.phone.substring(0,11))
        
        this.tjUSer = arr.filter(item => item.power == 0)[0]
        this.spUser = arr.filter(item => item.power == 1)[0]

        if (this.spUser) {
          this.pickMess = true;
          // 审批人信息
        } else {
          this.pickMess = false;
        }
      });
    },
    cancelTaskFormdialog() {},
    cancelTaskPeopleFormdialog() {},

    allHistoryList() {
      let taskForm = {
        current: this.appPage.current,
        limit: this.appPage.limit,
      };
      taskFormApi.allHistoryList(taskForm).then((res) => {
        this.appPage.total = res.data.data.total;
        this.tableData = res.data.data.list;
      });
    },
    rowStyle({ row, rowIndex }) {
      let stylejson = {}
      if (this.$route.query.id != undefined) {
        if (this.$route.query.id == row.jobNumber) {
          stylejson.backgroundColor = '#ffa8a8'
          stylejson.color = '#000'
          return stylejson
        } else {
          return ''
        }
      }
    },

    // 返回上一级
    goBack() {
      this.$router.push("/Select");
    },
    
  },
  watch: {
    // 利用watch方法检测路由变化：
    '$route':  (to, from) => {
      // 拿到目标参数 to.query.id 去再次请求数据接口
      if (to.query.randomId) {
        this.allHistoryList()
    }
    },
  },
};
</script>

<style lang="scss" scope>
.el-dialog__body {
  color: #3d6fd4;
}
</style>
